













































































import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { meetingsStore, calendarStore, teamsStore } from '@/store'
import CallActionsBtn from '@/components/HeaderBar/CallActionsBtn/index.vue'
import { copyToClipboard } from 'quasar'

interface Item {
  title: string,
  action: () => void,
  value?: boolean,
  withToggle?: boolean
}

@Component({
  components: {
    IconPhoneAdd: () => import('@/components/UI/icons/IconPhoneAdd.vue'),
    IconBookmark: () => import('@tada/icons/dist/IconBookmark.vue'),
    IconEdit2: () => import('@tada/icons/dist/IconEdit2.vue'),
    IconMore: () => import('@tada/icons/dist/IconMore.vue'),
    IconX: () => import('@tada/icons/dist/IconX.vue'),
    CallActionsBtn,
  },
})
export default class MeetingHeaderActions extends Vue {
  @VModel({
    required: true,
    type: Boolean,
  }) importantViewerActive!: boolean

  @Prop({
    required: true,
    type: Meeting,
  }) meeting!: Meeting

  private importantViewerActivated () {
    this.importantViewerActive = !this.importantViewerActive
  }

  private editMeeting (): void {
    window.goal('Calendar', { Calendar: 'Редактировать — из шапки встречи' })
    meetingsStore.actions.openMeetingDialog({ meeting: this.meeting })
  }

  private close (): void {
    meetingsStore.actions.setSelectedMeetingUUID(null)
    if (calendarStore.state.isCalendarWasOpenBefore) {
      this.$router.push({
        name: 'Calendar',
      })
    } else {
      this.$router.push({
        name: 'Main',
      })
    }
  }

  private get items (): Item[] {
    const items = []
    items.push(this.copyLinkItem)
    if (this.canDeleted) items.push(this.removeItem)
    if (!this.meeting.changeableFields) return items
    if (this.meeting.changeableFields.includes('notifications_enabled')) items.push(this.notificationItem)
    if (this.meeting.changeableFields.includes('counters_enabled')) items.push(this.countersItem)
    return items
  }

  private get removeItem (): Item {
    return {
      title: this.$t('meetings.rightBar.list.card.actions.delete').toString(),
      action: async () => {
        const isFreq = !!this.meeting.freq
        window.goal('Calendar', { Calendar: 'Удалить — из шапки встречи' })
        if (isFreq) {
          const title = this.$t('meetings.removeDialog.titleRepeatable', {
            name: this.meeting.displayName,
          }).toString()
          const okCaption =
            this.$t('meetings.removeDialog.okCaption').toString()
          const cancelCaption =
            this.$t('meetings.removeDialog.cancelCaption').toString()
          const description =
            this.$t('meetings.removeDialog.descriptionRepeatable').toString()

          this.$q.dialog({
            component: () => import('@/components/Meetings/components/ConfirmDialog.vue'),
            parent: this,
            okCaption,
            title,
            cancelCaption,
            description,
          }).onOk(async () => {
            await meetingsStore.actions.removeMeeting(this.meeting.jid)
            this.close()
          })
        } else {
          this.$q.dialog({
            component: () => import('@/components/Meetings/components/RemoveDialog.vue'),
            parent: this,
            meeting: this.meeting,
          }).onOk(async () => {
            await meetingsStore.actions.removeMeeting(this.meeting.jid)
            this.close()
          })
        }
      },
    }
  }

  private get notificationItem (): Item {
    return {
      title: this.$t('meetings.meeting.actions.notification').toString(),
      action: () => this.$emit('changeNotificationMode'),
      value: !!this.meeting.notificationsEnabled,
      withToggle: true,
    }
  }

  private get countersItem (): Item {
    return {
      title: this.$t('meetings.meeting.actions.counters').toString(),
      action: () => this.$emit('changeCountersEnabled'),
      value: !!this.meeting.countersEnabled,
      withToggle: true,
    }
  }

  private get copyLinkItem (): Item {
    return {
      title: this.$t('meetings.meeting.actions.link').toString(),
      action: () => {
        copyToClipboard(this.link)
        window.goal('Calendar', { Calendar: 'Скопировать ссылку — из шапки встречи' })
      },
    }
  }

  private get link () {
    return `${location.origin}/${teamsStore.getters.currentTeam.uid}/chats/${this.meeting.jid}`
  }

  private get canDeleted () {
    return this.meeting.canDelete
  }
}
